import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Grid} from "@material-ui/core";

interface LoaderProps<T = {}> {
    /**
     * @property _visible_ | Show/ Hide the loader as required
     * @default  false
     */
    visible?: boolean;
    /**
     * @property _type_ | Type of spinner you want to display
     * @default Audio
     */
    type:
        "Audio"
        | "BallTriangle"
        | "Bars"
        | "Circles"
        | "Grid"
        | "Hearts"
        | "Oval"
        | "Puff"
        | "Rings"
        | "TailSpin"
        | "ThreeDots"
        | "Watch"
        | "RevolvingDot"
        | "Triangle"
        | "Plane"
        | "MutatingDots"
        | "CradleLoader";
    /**
     * @property _height_ | Height prop define the height of the svg spinner.
     * @default 80px
     */
    height?: number | string;
    /**
     * @property _width_ | Width prop define the width of the spinner.
     * @default 80px
     */
    width?: number | string;
    /**
     * @property _color_ | color prop is for adding color to the spinner
     * @default Blue
     */
    color?: string;
    /**
     * @property _secondaryColor_ | secondaryColor prop  for now is available on Plane and MutatingDots loaders
     * @default Grey
     */
    secondaryColor?: string;
    /**
     * @property _timeout_ | Duration in miliseconds after which spinner is disable
     * d@default 0
     */
    timeout?: number;
    /**
     * @property _radius_ | Set radius if the loader has a circle element in it
     * @default value_varies
     */
    radius?: number;
}

export default function LoadSpinner(props: LoaderProps) {
    return (
        <Loader
            {...props}
            // timeout={3000} //3 secs
        />
    );
}

export function OpinionatedLoadSpinner(props: { hidden?: boolean, height?: number}) {
    let {hidden} = props
    return (
        <div style={{height: props.height || 500}}>
            <div style={{height: '100%'}} hidden={hidden}>
                <Grid style={{width: '100%', height: '100%'}} container justify={'center'}
                      alignItems={'center'} alignContent={'center'}>
                    <LoadSpinner type="Grid"
                                 color="#0d47a1"
                                 height={100}
                                 width={100}
                    />
                </Grid>
            </div>
        </div>
    )
}
