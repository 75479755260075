import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PopupWindow from './PopupWindow';
import {toQuery} from './utils';
import Button from "@material-ui/core/Button";

const responseTypeLocationKeys = {
    code: 'search',
    token: 'hash',

};

const responseTypeDataKeys = {
    code: 'code',
    token: 'access_token',
};

export function OAuth2Login2() {

}

class OAuth2Login extends Component {
    constructor(props) {
        super(props);
        this.onBtnClick = this.onBtnClick.bind(this);
        this.onRequest = this.onRequest.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);
    }

    onBtnClick() {
        const {
            buttonText, authorizationUrl, clientId, scope, redirectUri, state, responseType,
        } = this.props;
        const payload = {
            client_id: clientId,
            scope,
            redirect_uri: redirectUri,
            response_type: responseType,
        };
        for (const [key, value] of Object.entries(this.props.additionalPayload)) {
            payload[key] = value
        }
        if (state) {
            payload.state = state;
        }
        const search = toQuery(payload);
        const width = 680;
        const height = 440*2;
        const left = window.screenX + ((window.outerWidth - width) / 2);
        const top = window.screenY + ((window.outerHeight - height) / 2.5);
        const locationKey = responseTypeLocationKeys[responseType];
        const popup = PopupWindow.open(
            buttonText,
            `${authorizationUrl}?${search}`,
            {
                height, width, top, left,
            },
            {
                locationKey,
            },
        );
        this.popup = popup;

        this.onRequest();
        popup
            .then(this.onSuccess)
            .catch(this.onFailure);
    }

    onRequest() {
        const {onRequest} = this.props;
        onRequest();
    }

    onSuccess(data) {
        const {responseType, onSuccess} = this.props;
        const responseKey = responseTypeDataKeys[responseType];
        if (!data[responseKey]) {
            console.error('received data', data);
            return this.onFailure(new Error(`'${responseKey}' not found in received data`));
        }

        return onSuccess(data);
    }

    onFailure(error) {
        const {onFailure} = this.props;
        onFailure(error);
    }

    render() {
        const {className, buttonText, children} = this.props;
        const attrs = {onClick: this.onBtnClick};

        if (className) {
            attrs.className = className;
        }

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Button disabled={this.props.buttonDisabled}
                       variant={'outlined'} {...attrs}>{children || buttonText}</Button>;
    }
}

OAuth2Login.defaultProps = {
    buttonText: 'Login',
    scope: '',
    state: '',
    className: '',
    children: null,
    additionalPayload: {},
    onRequest: () => {
    },
};


OAuth2Login.propTypes = {
    buttonDisabled: PropTypes.bool,
    authorizationUrl: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    redirectUri: PropTypes.string.isRequired,
    responseType: PropTypes.oneOf(['code', 'token']).isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    onRequest: PropTypes.func,
    scope: PropTypes.string,
    state: PropTypes.string,
    additionalPayload: PropTypes.object,
};


export default OAuth2Login;
