import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

export interface ILinkItem {
    label: string
    onClick?: () => any
}

export interface ILinkListProps {
    items: ILinkItem[]
    children?: any
}

export default function LinkList(props: ILinkListProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button aria-controls="link-list" aria-haspopup="true" onClick={handleClick}>
                {props.children || <MoreHorizIcon/>}
            </Button>
            <Menu
                id="link-list"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.items.map((x, idx) => (
                    <MenuItem button={x.onClick ? true : undefined} key={idx} onClick={() => {
                        if (x.onClick) {
                            x.onClick()
                        }
                        handleClose()
                    }}>{x.label}</MenuItem>
                ))}
            </Menu>
        </div>
    );
}
