import {IAskQuestionResp} from "./api";
import React from "react";

export function copyObj<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T
}


export function multipleChoiceCounter(answers: IAskQuestionResp[], options: string[], singleSelection: boolean = true) {
    const mcCount = new Map<string, number>()
    if (!answers || !options) {
        return []
    }
    options.forEach(x => {
        mcCount.set(x, 0)
    })
    answers.map(x => x.labels).forEach(labels => {
        // !labels covers the empty labels case but this is more readable
        if (!labels || labels.length === 0) {
            mcCount.set('Other', (mcCount.get('Other') || 0) + 1)
        } else {
            if (singleSelection) {
                mcCount.set(labels[0], (mcCount.get(labels[0]) || 0) + 1)
            } else {
                labels.forEach(lab => {
                    mcCount.set(lab, (mcCount.get(lab) || 0) + 1)
                })
            }
        }
    })

    return options.map(x => mcCount.get(x) || 0)
}


export function renderStatisticInvertable(inverted: boolean = false) {
    return (label: number) => {
        if (isNaN(label)) {
            label = Number.POSITIVE_INFINITY
        }
        if (label >= 0 && label <= 1) {
            label *= 100
        }
        label = Math.floor(label)
        if (label === 0) {
            return (
                <span>{label}%</span>
            )
        }

        if (inverted) {
            return (
                <span style={{color: label >= 0 ? 'red' : 'green'}}>{label >= 0 ? '+' : ''}{label}%</span>
            )
        }

        return (
            <span style={{color: label >= 0 ? 'green' : 'red'}}>{label >= 0 ? '+' : ''}{label}%</span>
        )
    }
}

export function renderStatistic(label: number) {
    if (isNaN(label)) {
        label = Number.POSITIVE_INFINITY
    }
    if (label >= 0 && label <= 1) {
        label *= 100
    }
    label = Math.floor(label)
    if (label === 0) {
        return (
            <span>{label}%</span>
        )
    }

    return (
        <span style={{color: label >= 0 ? 'green' : 'red'}}>{label >= 0 ? '+' : ''}{label}%</span>
    )
}
