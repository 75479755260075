import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppPostAuth from './AppPostAuth';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider, useAuth0} from "./react-auth0-spa";
import config from "./data/auth_config.json"
import {BrowserRouter as Router, useHistory} from "react-router-dom";
import firebase from "firebase/app";
import {OpinionatedLoadSpinner} from "./Components/LoadSpinner";
import axios from "axios";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

// CSS for react-date-range library
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {platformApiUri} from "./util/constants"; // theme css file


Sentry.init({
    dsn: "https://79a3792709494317b04841696e78dabe@o524987.ingest.sentry.io/5638486",
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
});


axios.defaults.baseURL = platformApiUri
// axios.defaults.baseURL = 'http://localhost:8080'
console.log(`'API BASE URI SET TO ${platformApiUri}`)
const FullStory = require('@fullstory/browser')

firebase.initializeApp({
    apiKey: 'AIzaSyAWBSzLnlVbTwYBhx3MKzHkCE_VSKAung0',
    authDomain: 'supply-sights.firebaseapp.com',
    databaseURL: 'https://supply-sights.firebaseio.com',
    projectId: 'supply-sights',
    storageBucket: 'supply-sights.appspot.com',
    messagingSenderId: '204415715404',
    appId: '1:204415715404:web:5361bac121a0478b848ce9',
    measurementId: 'G-LCFKP4MKDS',
});

FullStory.init({
    orgId: 'T1R33',
    debug: false,
    script: 'public.assets.styloml.com/javascript/fullstory.js',
    // host: 'fs-proxy.styloml.com'
    // TODO (Josh) fix fs-proxy host and then uncomment the above
});

const authJwtAudience = 'https://api.platform.styloml.com';

function OnlyIfAuthenticated(props: any) {
    const auth0 = useAuth0()
    const {loading, isAuthenticated, loginWithRedirect} = useAuth0();
    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: {targetUrl: window.location.pathname}
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect])
    console.log('pre-auth', auth0)
    if (auth0.loading) {
        return (
            <OpinionatedLoadSpinner/>
        )
    }
    if (!auth0.isAuthenticated) {
        return (
            <div/>
        )
    }
    return props.children
}

function PreApp() {
    const history = useHistory()

    const onRedirectCallback = (appState: any) => {
        history.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    };
    return (
        <Auth0Provider
            domain={config.domain}
            client_id={config.clientId}
            cacheLocation='localstorage' // 'localstorage' or 'memory'
            audience={authJwtAudience}
            redirect_uri={window.location.origin}
            // @ts-ignore
            onRedirectCallback={onRedirectCallback}
        >
            <OnlyIfAuthenticated>
                <AppPostAuth/>
            </OnlyIfAuthenticated>
        </Auth0Provider>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <PreApp/>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
