import { Divider, List } from "@material-ui/core";
import { TagData, SelectorFcn, DuplicatesFcn } from "./TagTypes";
import TagListItem from "./TagListItem";
import React from "react";

type TagListProps = {
  model: TagData[],
  lineage: string[],
  selector: SelectorFcn,
  onSave: () => Promise<void>,
  depth: number,
  duplicates: DuplicatesFcn,
  domain: string,
}
export function TagList(props:TagListProps):React.ReactElement {
  const listContent:React.ReactElement[] = [];
  props.model.forEach((tag, i) => {
    if (i !== 0) {
      listContent.push(<Divider key={i + 0.5} />)
    }

    listContent.push(<TagListItem domain={props.domain} key={i} model={tag} duplicates={props.duplicates} selector={props.selector} lineage={props.lineage} depth={props.depth} onSave={props.onSave} />);
  });

  return (
    <List style={ {padding: '0px'} }>
      { listContent }
    </List>
  );
}