import { Link, ListItem, ListItemIcon, Checkbox, ListItemText, Typography, Modal } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { TagData, TierLabels, SelectorFcn, DuplicatesFcn, makeParameters, TagStyles, styloPurple } from "./TagTypes";
import { TagDetailsLightbox } from "./TagDetailsLightbox";

type TagListItemProps = {
  model: TagData,
  selector: SelectorFcn,
  lineage: string[],
  onSave: () => Promise<void>,
  depth: number,
  duplicates: DuplicatesFcn,
  domain: string,
}
export default function TagListItem(props:TagListItemProps):React.ReactElement {
  const [hover, setHover] = useState(false);
  const classes = TagStyles();
  const [editorOpen, setEditorOpen] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    props.selector(props.lineage.concat([props.model.label]), newValue);
  };
  const hoverIn = () => {
    setHover(true);
  };
  const hoverOut = () => {
    setHover(false);
  }
  const editClick:React.MouseEventHandler = (event:React.MouseEvent) => {
    event.stopPropagation();
    setEditorOpen(true);
  };
  const editSave = (oldValue:string, newValue:string, parents:string[]):void => {
    const requestEdit = async () => {
      await axios.put('/tags/tag', { 
        ...Object.fromEntries(parents.map((p, i) => [TierLabels[i].singular, p])),
        old: oldValue,
        new: newValue
      }, makeParameters(props.domain));
      props.onSave();
    };
    requestEdit();
    setEditorOpen(false);
  };
  const editCancel = () => {
    setEditorOpen(false);
  };

  return (<ListItem className={((props.model.selected || hover) ? classes.selectedItem : '')} onMouseEnter={hoverIn} onMouseLeave={hoverOut}>
    <ListItemIcon>
      <Checkbox style={{ color: styloPurple }} onChange={handleChange} checked={props.model.selected} />
    </ListItemIcon>
    <ListItemText>
      <div style={{ display: 'flex' }}>
        <Typography>{props.model.label}</Typography>
        {hover ? <Link href="#" className={classes.expandLink} underline="none" onClick={editClick}>Edit</Link> : undefined}
        <Modal open={editorOpen} onClose={() => setEditorOpen(false)}>
          <TagDetailsLightbox
            label={props.model.label}
            parents={props.lineage}
            depth={props.depth}
            duplicates={props.duplicates}
            domain={props.domain} 
            closeFcn={editCancel}
            executeFcn={editSave}
          />
        </Modal>
      </div>
    </ListItemText>
  </ListItem>);
}