import { Typography, TextField, capitalize, Button } from "@material-ui/core";
import { useState } from "react";
import { DuplicatesFcn, LightboxStyles, TierLabels } from "./TagTypes";

type TagDetailsLightboxProps = {
  duplicates: DuplicatesFcn,
  depth: number,
  numChildren?: number,
  label: string,
  parents: string[],
  executeFcn: (oldValue:string, newValue:string, parents:string[]) => void,
  closeFcn: () => void,
  domain: string,
};
export function TagDetailsLightbox(props:TagDetailsLightboxProps) {
  const classes = LightboxStyles();
  const [newLabel, setNewLabel] = useState(props.label);

  const getItemType = () => TierLabels[props.depth].singular;  

  const handleChange:React.ChangeEventHandler<HTMLInputElement> = (event:React.ChangeEvent<HTMLInputElement>) => {
    setNewLabel(event.currentTarget.value);
  };

  const labelModified = () => props.label !== newLabel;
  const labelDuplicated = () => props.duplicates(newLabel, props.depth, props.parents.slice(0, 1));
  const readyToSubmit = ():boolean => {
    return labelModified() && !labelDuplicated();
  };
  
  return (<div className={classes.lightbox} onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
    <div className={classes.contentBlock}>
      <Typography className={classes.heading}>{getItemType()} Details</Typography>
    </div>
    <div className={classes.captionBlock}>
      <Typography className={classes.caption}>{getItemType()}</Typography>
    </div>
    <div className={classes.contentBlock}>
      <TextField
        className={classes.textInput}
        variant="outlined"
        value={newLabel}
        onChange={handleChange}
        error={labelModified() && labelDuplicated()}
        helperText={labelModified() && labelDuplicated() ? `${capitalize(TierLabels[props.depth].singular)} name already exists inside ${TierLabels[props.depth-1].singular}` : ''}
      />
    </div>
    { Array.from(new Array(Math.min(TierLabels.length, props.depth+2)), (_, i) => {
      if (i < props.depth) {
        return (<div className={classes.captionBlock}><Typography className={classes.caption}>{capitalize(TierLabels[i].singular)}</Typography><Typography className={classes.captionedValue}>{props.parents[i]}</Typography></div>);
      } else if (i > props.depth) {
        return (<div className={classes.captionBlock}><Typography className={classes.caption}>Number of {capitalize(TierLabels[i].plural)}</Typography><Typography className={classes.captionedValue}>{props.numChildren}</Typography></div>);
      }
    }) }
    <div className={classes.contentBlock}>
      <div className={classes.buttonBlock}>
        <Button className={classes.cancelButton} variant="outlined" onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          props.closeFcn();
        } }>Cancel</Button>
        <Button className={classes.saveButton} variant="outlined" disabled={!readyToSubmit()} onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          props.executeFcn(props.label, newLabel, props.parents);
        } }>Save</Button>
      </div>
    </div>
  </div>);
}