import { Typography, Button } from "@material-ui/core";
import { LightboxStyles } from "./TagTypes";

type TagDeleteLightboxProps = {
  deletePaths: string[][],
  executeFcn: (paths: string[][]) => void,
  closeFcn: React.MouseEventHandler
};
export function TagDeleteLightbox(props:TagDeleteLightboxProps) {
  const classes = LightboxStyles();
  
  return (<div className={classes.lightbox}>
    <div className={classes.contentBlock}>
      <Typography className={classes.heading}>Delete {props.deletePaths.length} tags?</Typography>
    </div>
    <div className={classes.contentBlock}>
      <div className={classes.tagList}>
        { props.deletePaths.map(lineage => (<Typography>
          { lineage.map((tag, i) => ((i !== 0) ? ': ' : '') + tag)}
        </Typography>)) }
      </div>
    </div>
    <div className={classes.contentBlock}>
      <div className={classes.buttonBlock}>
        <Button className={classes.cancelButton} variant="outlined" onClick={props.closeFcn}>Cancel</Button>
        <Button className={classes.deleteButton} variant="outlined" onClick={ (event: React.MouseEvent) => {      
          event.stopPropagation();
          props.executeFcn(props.deletePaths);
         } }>Yes, delete tags</Button>
      </div>
    </div>
  </div>);
}