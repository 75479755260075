import {useEffect, useState} from "react";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

export interface IUseAxiosResponse<T> {
    loading: boolean,
    error: any,
    resp?: AxiosResponse<T>
}

export function useAxiosRequest<T>(req: AxiosRequestConfig, pollSeconds?: number) {
    const [loading, loadingSetter] = useState(true)
    const [error, errorSetter] = useState<any>()
    const [resp, respSetter] = useState<AxiosResponse<T>>()

    const [pollCount, pollCountSetter] = useState<number>(0)

    const reqJson = JSON.stringify(req)

    useEffect(() => {
        const req = JSON.parse(reqJson)
        // TODO add proper http cancel
        loadingSetter(true)
        errorSetter(undefined)
        respSetter(undefined)
        let canceled = false

        axios.request<T>(req).then(x => {
            if (!canceled) {
                respSetter(x)
            }
        }).catch(e => {
            if (!canceled) {
                errorSetter(e)
            }
        }).finally(() => {
            if (!canceled) {
                loadingSetter(false)
            }
        })

        let tid: NodeJS.Timeout;
        if (pollSeconds !== undefined) {
            tid = setTimeout(() => {
                if (canceled) {
                    return
                }
                console.log(`Polling: refresh #${pollCount}`)
                pollCountSetter(pollCount + 1)
            }, pollSeconds * 1000)
        }

        return () => {
            canceled = true
            if (tid !== undefined) {
                clearTimeout(tid)
            }
            loadingSetter(true)
        }
    }, [pollCount, pollSeconds, reqJson])

    const reload = () => {
        pollCountSetter(pollCount+1)
    }

    return {loading, error, resp, reload}
}

export interface IAxiosRequestProps<T> {
    req: AxiosRequestConfig
    children: (loading: boolean, error?: any, result?: AxiosResponse<T>, reload?: () => void, reloadCounter?: number) => any
    defer?: boolean
}

// TODO medium priority: For some reason, this reloads on every re-render.
//  Even if no useEffect deps have changed. This is probably due to it
//  incorrectly believing the request obj changed
export function AxiosRequest<T>(props: IAxiosRequestProps<T>) {
    const defer = !!props.defer

    const [loading, loadingSetter] = useState(!defer)
    const [error, errorSetter] = useState<any>()
    const [result, resultSetter] = useState<AxiosResponse<T>>()

    const [reloadCounter, reloadCounterSetter] = useState(0)

    function reload() {
        reloadCounterSetter(reloadCounter + 1)
    }


    useEffect(() => {
        if (defer && reloadCounter === 0) {
            return
        }
        // TODO add proper http cancel
        loadingSetter(true)
        errorSetter(undefined)
        resultSetter(undefined)
        let canceled = false
        console.debug(`reload #${reloadCounter} for url ${props.req.url}`)

        axios.request<T>(props.req).then(x => {
            if (!canceled) {
                resultSetter(x)
            }
        }).catch(e => {
            if (!canceled) {
                errorSetter(e)
            }
        }).finally(() => {
            if (!canceled) {
                loadingSetter(false)
            }
        })

        return () => {
            canceled = true
            loadingSetter(true)
        }
    }, [defer, props.req, reloadCounter])

    return props.children(loading, error, result, reload, reloadCounter)
}
