import React, { useEffect } from 'react';
import { Button, Modal } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { DomainList } from '../DomainList';
import axios from 'axios';
import { RawCategoryData, TagData, DescriptorData, CategoryData, isCategoryData, existsAtDepth, RawTagData, TierLabels, DuplicatesFcn, SelectorFcn, TagStyles, makeParameters } from './TagTypes';
import { CategoryAccordion } from './CategoryAccordion';
import { TagAddLightbox } from './TagAddLightbox';
import { TagDeleteLightbox } from './TagDeleteLightbox';

export default function TagManager() {
  const classes = TagStyles();
  var [hierarchicalData, setHierarchicalData] = React.useState([] as CategoryData[]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [domain, setDomain] = React.useState('');

  const getTagData = async () => {
    const response = await axios.get('/tags/get', makeParameters(domain));
    const rawData:RawCategoryData = response.data;

    const refineTags = (data:RawTagData[]):TagData[] => {
      return data.map((datum) => { return { label: datum.tag, selected: false }; });
    };

    const refineCategory = (data:RawCategoryData, oldHierarchy:CategoryData[] | undefined, depth:number):DescriptorData => {
      return Object.keys(data).map((key) => {
        var oldItem = oldHierarchy?.find((val) => val.identifier === key);
        var nextHierarchy = oldItem && isCategoryData(oldItem.subs) ? oldItem.subs : undefined;
        return {
          identifier: key,
          expanded: oldItem?.expanded || false,
          subs: (depth === TierLabels.length-2)
            ? refineTags(data[key] as RawTagData[])
            : refineCategory(data[key] as RawCategoryData, nextHierarchy, depth+1)
        };
      });
    };
    
    const result:CategoryData[] = refineCategory(rawData, hierarchicalData, 0) as CategoryData[];
  
    setHierarchicalData(result);
  };
  
  useEffect(() => { getTagData() }, [domain]); // runs at startup and when domain changes to re-populate

  const anySelected = ():boolean => {
    const tagReducer = (prev:boolean, curr:TagData):boolean => {
      return prev || curr.selected;
    };
    const categoryReducer = (prev:boolean, curr:CategoryData):boolean => {
      if (isCategoryData(curr.subs)) {
        return curr.subs.reduce(categoryReducer, prev);
      } else {
        return curr.subs.reduce(tagReducer, prev);
      }
    };

    return hierarchicalData.reduce(categoryReducer, false);
  };
  const setSelected:SelectorFcn = (lineage:string[], value:boolean):void => {
    const newValue = [...hierarchicalData];
    let workingData:DescriptorData = newValue;
    lineage.forEach((label, i) => {
      if (isCategoryData(workingData)) {
        workingData = (workingData.find(x => x.identifier === label)?.subs as DescriptorData);
      } else {
        (workingData.find(x => x.label === label) as TagData).selected = value;
      }
    });

    setHierarchicalData(newValue);
  };
  const getSelected = ():string[][] => {
    const selected:string[][] = [];

    const categoryDataIterator = (lineage:string[]) => {
      return (x:CategoryData) => {
        if (isCategoryData(x.subs)) {
          x.subs.forEach(categoryDataIterator(lineage.concat([x.identifier])));
        } else {
          x.subs.forEach(y => {
            if (y.selected) {
              selected.push(lineage.concat([x.identifier, y.label]));
            }
          });
        }
      };
    }

    hierarchicalData.forEach(categoryDataIterator([]));
    return selected;
  };

  const setExpanded:SelectorFcn = (lineage: string[], value: boolean):void => {
    const newValue = [...hierarchicalData];
    let workingData:DescriptorData = newValue;
    lineage.forEach((label, i) => {
      if (isCategoryData(workingData)) {
        let d = workingData.find(x => x.identifier === label);
        if (i === lineage.length-1) {
          if (d) d.expanded = value;
        } else {
          workingData = d?.subs as DescriptorData;
        }
      } else {
        throw new Error('something'); // FIXME
      }
    });

    setHierarchicalData(newValue);
  };

  const deleteCancel = (event:React.MouseEvent) => {
    event.stopPropagation();
    setDeleteOpen(false);
  };
  const deleteSave = (paths:string[][]) => {
    const requestDelete = async () => {
      const deleteData = (path:string[]) => Object.fromEntries(TierLabels.map((label, i) => [label.singular, path[i]]));
      try {
        await Promise.all(paths.map(path => axios.delete('/tags/delete', { data: deleteData(path), ...makeParameters(domain) })));
        await getTagData();
      } catch {}
    };
    requestDelete();
    setDeleteOpen(false);
  };

  const addCancel = (event:React.MouseEvent) => {
    event.stopPropagation();
    setAddOpen(false);
  };
  const addSave = (newValue:string, ancestry:string[]):void => {
    const requestSave = async () => {
      const postData = Object.fromEntries(TierLabels.map((label, i) => [label.singular, i === TierLabels.length-1 ? newValue : ancestry[i]]));
      try {
        await axios.post('/tags/new', postData, makeParameters(domain));
        await getTagData();
      } catch {}
    };
    if (ancestry[0]) {
      requestSave();
    }
    setAddOpen(false);
  };

  const duplicates:DuplicatesFcn = (query:string, depth:number, lineage?:string[]) => existsAtDepth(hierarchicalData, query, depth, lineage?.slice(0,1));

  return (
    <div>
      <div>
        <Button variant="contained" disableElevation={true} startIcon={<AddBoxOutlinedIcon />} className={classes.button} onClick={() => setAddOpen(true)}>Add Tag</Button>
        <Modal open={addOpen} onClose={() => setAddOpen(false)}>
          <TagAddLightbox hierarchicalData={hierarchicalData} closeFcn={addCancel} executeFcn={addSave} />
        </Modal>
        { anySelected()
          ? <Button variant="contained" disableElevation={true} startIcon={<HighlightOff />} className={classes.button + ' ' + classes.deleteButton} onClick={() => setDeleteOpen(true)}>Delete</Button>
          : undefined
        } 
        <Modal open={deleteOpen} onClose={() => setDeleteOpen(false)}>
          <TagDeleteLightbox deletePaths={getSelected()} closeFcn={deleteCancel} executeFcn={deleteSave} />
        </Modal>
      </div>
      
      <div><DomainList domain={domain} onChange={setDomain} /></div>
      { hierarchicalData.map((d, i) => (<CategoryAccordion key={i} model={d} depth={0} lineage={[]} duplicates={duplicates} domain={domain} selector={setSelected} expander={setExpanded} onSave={getTagData} />)) }
    </div>
  );
}