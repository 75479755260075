import React from 'react';
import Navigation from './Components/Navigation'
import {Redirect, Route, Switch} from "react-router-dom";
import SettingsPage from "./Pages/SettingsPage";
// @ts-ignore
import OAuth2Login from './Components/OAuthLogin';
import StyloAdmin from "./Pages/StyloAdmin";
import TagManagerPage from './Pages/TagManagerPage';

function AppPostAuth() {
    return (
        <Navigation>
            <RouteContent/>
        </Navigation>
    );
}

function RouteContent() {
    return (
        <div>
            <Switch>
                <Route path="/settings">
                    <SettingsPage/>
                </Route>
                <Route path="/tags">
                    <TagManagerPage/>
                </Route>
                <Route path={'/admin/global'}>
                    <StyloAdmin/>
                </Route>
                <Route path='/integrations/oauth2/sfdc/redirect'>
                    <div>Authorization</div>
                </Route>
                <Route exact path="/">
                    <Redirect to="/settings" />
                </Route>
            </Switch>
        </div>
    )
}


export default AppPostAuth;
