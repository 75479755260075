import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SecurityIcon from '@material-ui/icons/Security';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemText from '@material-ui/core/ListItemText';
import {useHistory} from "react-router-dom";
import {Avatar, Tooltip} from "@material-ui/core";
import LinkList from "./LinkList";
import {useAuth0} from "../react-auth0-spa";
import logo from '../logos/stylo-favicon-32.png'
import ConditionalShow from './ConditionalLoad';
import axios from 'axios';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    }),
);

const LOGO_URL = 'https://uploads-ssl.webflow.com/6168953ba33aabee577a1ebc/616f6692f48e5a04bf318d58_stylo-logo.svg';

export default function Navigation(props: any) {
    const history = useHistory()
    const classes = useStyles();
    const auth0 = useAuth0()

    const [hasDatasets, setHasDatasets] = useState(false);
    useEffect(() => {
        (async () => {
            const response = await axios.get('/tags/get');
            setHasDatasets(Object.keys(response.data).length > 0);
        })();
    })

    const getDomain = () => auth0.user.email.split('@')[1];

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                color={'inherit'}
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar>
                    <img src={LOGO_URL} style={{marginRight: 5}} alt={'logo'} height={32} width={32}/>
                    <Typography variant="h6" noWrap onClick={() => history.push('/')} style={{cursor: 'pointer'}}>
                        Stylo
                    </Typography>
                    <div style={{marginLeft: 'auto'}}>
                        <LinkList items={[
                            {
                                label: auth0.user.name || auth0.user.email,
                            },
                            {
                                label: 'Sign Out',
                                onClick: () => auth0?.logout ? auth0.logout() : console.log('auth0 is undefined')
                            }
                        ]}>
                            <Avatar
                                style={{width: 40, height: 40}}
                                alt={auth0.user.label}
                                src={auth0.user.picture}
                            />

                        </LinkList>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerClose]: true,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerClose]: true,
                    }),
                }}
            >
                <div className={classes.toolbar}>

                </div>
                <Divider/>
                <List>
                    <Tooltip title="Tag Management" aria-label="settings" placement="right">
                        <ListItem
                            onClick={() => {
                                history.push(`/tags`)
                            }}
                            button key={'tags'}
                            disabled={!hasDatasets}>
                            <ListItemIcon> <EditAttributesIcon/> </ListItemIcon>
                            <ListItemText primary={'Tag Management'}/>
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Settings" aria-label="settings" placement="right">
                        <ListItem onClick={() => {
                            history.push(`/settings`)
                        }}
                                  button key={'settings'}>
                            <ListItemIcon> <SettingsIcon/> </ListItemIcon>
                            <ListItemText primary={'Settings'}/>
                        </ListItem>
                    </Tooltip>
                    <ConditionalShow visible={['styloml.com', 'askstylo.com', 'styloteams.com'].indexOf(getDomain()) >= 0}>
                        <Tooltip title="Admin" aria-label="admin" placement="right">
                            <ListItem onClick={() => {
                                history.push(`/admin/global`)
                            }}
                                    button key={'admin'}>
                                <ListItemIcon> <SecurityIcon/> </ListItemIcon>
                                <ListItemText primary={'Admin'}/>
                            </ListItem>
                        </Tooltip>
                    </ConditionalShow>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                {props.children ? props.children : []}
            </main>
        </div>
    );
}
