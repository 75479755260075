import {OpinionatedLoadSpinner} from "./LoadSpinner";

export default function ConditionalShow(props: { children: any, visible?: boolean, hidden?: boolean, loadingWhileHidden?: boolean }) {
    if (props.hidden === undefined && props.visible === undefined) {
        throw Error('Must have either hidden or visible set')
    }
    if (props.hidden !== undefined && props.visible !== undefined) {
        throw Error('Both hidden and visible can not both be set')
    }
    let visible = props.visible
    if (props.hidden !== undefined) {
        visible = !props.hidden
    }
    if (visible) {
        return props.children
    }
    if (props.loadingWhileHidden) {
        return <OpinionatedLoadSpinner/>
    }
    return <></>
}
