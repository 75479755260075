import React, {useContext, useEffect, useState} from "react";
import createAuth0Client, {Auth0Client} from "@auth0/auth0-spa-js";
import * as FullStory from '@fullstory/browser';
import axios from "axios";
import {useAxiosRequest} from "./Components/AxiosRequest";
import {IDataContainer} from "./util/api";

interface IAuth0ContextValues {
    isAuthenticated?: boolean,
    user?: any,
    token?: string,
    grpcMeta?: any,
    loading?: any,
    popupOpen?: any,
    loginWithPopup?: any,
    handleRedirectCallback?: any,
    getIdTokenClaims?: any,
    loginWithRedirect?: any,
    getTokenSilently?: any,
    getTokenWithPopup?: any,
    logout?: any,
}

const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<IAuth0ContextValues>(undefined as any);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
                                  // @ts-ignore
                                  children,
                                  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                                  ...initOptions
                              }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const [user, setUser] = useState<any>();
    const [authToken, setAuthToken] = useState<string>();
    const [auth0Client, setAuth0] = useState<Auth0Client>();
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);


    useEffect(() => {
        let iid: number;
        let canceled = false;

        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(initOptions as any);
            setAuth0(auth0FromHook as any);

            if (window.location.search.includes("code=") &&
                window.location.search.includes("state=")) {
                const {appState} = await auth0FromHook.handleRedirectCallback();
                // @ts-ignore
                onRedirectCallback(appState);
            }

            const isAuthenticated = await auth0FromHook.isAuthenticated();

            setIsAuthenticated(isAuthenticated);
            if (isAuthenticated) {
                const user = await auth0FromHook.getUser();
                console.log('user authenticated')
                if (user && user.sub) {
                    FullStory.identify(user.sub, {
                        displayName: user.name,
                        email: user.email,
                    });
                }
                setUser(user);
                console.log('user:', user)
                const setToken = (tok: string) => {
                    setAuthToken(tok);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + tok
                    console.log('token set', tok)
                };
                const tok = await auth0FromHook.getTokenSilently();
                setToken(tok);
                if (!canceled) {
                    iid = window.setInterval(() => {
                        auth0FromHook.getTokenSilently().then(tok => {
                            setToken(tok);
                            console.log("refreshed auth token");
                        })
                    }, 1000 * 60 * 30);
                }
            }
            setLoading(false);
        };
        initAuth0();
        return () => {
            canceled = true;
            if (iid !== undefined) {
                clearInterval(iid);
            }
        }
        // eslint-disable-next-line
    }, []);


    const loginWithPopup = async (params = {}) => {
        setPopupOpen(true);
        try {
            // @ts-ignore
            await auth0Client.loginWithPopup(params);
        } catch (error) {
            console.error(error);
        } finally {
            setPopupOpen(false);
        }
        const user = await auth0Client?.getUser();
        setUser(user);
        setIsAuthenticated(true);
    };

    const handleRedirectCallback = async () => {
        setLoading(true);
        // @ts-ignore
        await auth0Client.handleRedirectCallback();
        // @ts-ignore
        const user = await auth0Client.getUser();
        setLoading(false);
        setIsAuthenticated(true);
        setUser(user);
    };
    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                token: authToken,
                loading,
                popupOpen,
                loginWithPopup,
                // @ts-ignore
                handleRedirectCallback,
                // @ts-ignore
                getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
                // @ts-ignore
                loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
                // @ts-ignore
                getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
                // @ts-ignore
                getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
                // @ts-ignore
                logout: (...p) => auth0Client.logout(...p)
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};

