import { withStyles, Accordion, AccordionSummary, AccordionDetails, Typography, Modal, Link } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { SelectorFcn, DuplicatesFcn, makeParameters, TagStyles, CategoryData, TierLabels, isCategoryData } from "./TagTypes";
import { TagDetailsLightbox } from "./TagDetailsLightbox";
import { TagList } from "./TagList";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type CategoryAccordionProps = {
  model: CategoryData,
  depth: number,
  lineage: string[],
  selector: SelectorFcn,
  onSave: () => Promise<void>,
  duplicates: DuplicatesFcn,
  domain: string,
  expander: SelectorFcn,
};
export function CategoryAccordion(props:CategoryAccordionProps):React.ReactElement {
  const classes = TagStyles();
  const StyledAccordion = withStyles({
    root: {
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(Accordion);

  const StyledAccordionSummary = withStyles({
    root: {
      '&$expanded': {
        minHeight: '0px'
      }
    },
    content: {
      '&$expanded': {
        margin: 'auto'
      }
    },
    expanded: {},
  })(AccordionSummary);

  const StyledAccordionDetails = withStyles({
    root: {
      display: 'block'
    }
  })(AccordionDetails);

  const [hover, setHover] = useState(false);
  const [editorOpen, setEditorOpen] = React.useState(false);

  const expandClick = (event: React.ChangeEvent<{}>, newValue: boolean) => {
    event.stopPropagation();
    props.expander(props.lineage.concat(props.model.identifier), newValue);
  };

  const hoverIn = () => {
    setHover(true);
  };
  const hoverOut = () => {
    setHover(false);
  };
  const editClick:React.MouseEventHandler = (event:React.MouseEvent) => {
    event.stopPropagation();
    setEditorOpen(true);
  };
  const editSave = (oldValue:string, newValue:string, parents:string[]) => {
    const requestEdit = async () => {
      await axios.put('/tags/category', { 
        old: oldValue,
        new: newValue,
        ...Object.fromEntries(parents.map((p, i) => [TierLabels[i].singular, p])),
      }, makeParameters(props.domain));
      await props.onSave();
    };
    requestEdit();
    setEditorOpen(false);
  };
  const editCancel = () => {
    setEditorOpen(false);
  };

  const indentStyle:React.CSSProperties = {
    padding: `0px ${20 * props.depth}px`
  };

  const sublabel = TierLabels[props.depth+1][props.model.subs.length === 1 ? 'singular' : 'plural'];
  
  return (
    <StyledAccordion expanded={props.model.expanded} onChange={expandClick}>
      <StyledAccordionSummary
        style={indentStyle}
        className={(hover ? classes.selectedItem : '') + ' ' + classes.accordionSummary}
        expandIcon={<ExpandMoreIcon className={ classes.downArrow }/>}
        onMouseEnter={hoverIn}
        onMouseLeave={hoverOut}
      >
        <Typography className={classes.accordionHeading}>{ props.model.identifier }</Typography>
        <Typography className={classes.accordionSubheading}>{ props.model.subs.length } {sublabel}</Typography>
        {hover && TierLabels[props.depth].editable ? (<Link href="#" className={classes.expandLink} underline="none" onClick={editClick}>Edit</Link>) : undefined}
        <Modal open={editorOpen} onClose={() => setEditorOpen(false)}>
          <TagDetailsLightbox
            label={props.model.identifier}
            depth={props.depth}
            parents={props.lineage}
            domain={props.domain}
            numChildren={props.model.subs.length}
            duplicates={props.duplicates}
            closeFcn={editCancel}
            executeFcn={editSave}
          />
        </Modal>
      </StyledAccordionSummary>
      <StyledAccordionDetails style={ {padding: '0'}}>
        { isCategoryData(props.model.subs)
          ? props.model.subs.map((x, i) => 
              <CategoryAccordion
                key={i}
                depth={props.depth+1}
                lineage={props.lineage.concat([props.model.identifier])}
                duplicates={props.duplicates}
                selector={props.selector}
                model={x}
                onSave={props.onSave}
                domain={props.domain}
                expander={props.expander}
              />
            )
          : <TagList
              model={props.model.subs}
              lineage={props.lineage.concat([props.model.identifier])}
              depth={props.depth+1}
              duplicates={props.duplicates}
              domain={props.domain}
              selector={props.selector}
              onSave={props.onSave}
            />
        }
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}