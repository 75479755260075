import { makeStyles, Theme } from '@material-ui/core/styles';

export type DuplicatesFcn = (label:string, depth:number, lineage?:string[]) => boolean;
export type SelectorFcn = (lineage:string[], value:boolean) => void;

export const makeParameters = (d:string) => d ? { params: { domain: d } } : undefined;

export const styloPurple = '#513BE3';

export const TagStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#F3F3F3',
    marginRight: '10px',
    marginBottom: '10px',
    color: '#3B3A3D',
    textTransform: 'none'
  },
  deleteButton: {color: '#EC5540', backgroundColor: '#F3F3F3'},
  downArrow: {
    color: styloPurple,
    order: -1
  },
  accordionHeading: {
    fontWeight: 700,
  },
  accordionSubheading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    paddingLeft: '10px',
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    backgroundColor: '#E2DEFA'
  },
  selectedItem: {
    backgroundColor: '#F7F6FC',
    fontWeight: 700
  },
  expandLink: {
    color: styloPurple,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(15),
    paddingLeft: '10px',
    textTransform: 'uppercase'
  }
}));

export const LightboxStyles = makeStyles((theme: Theme) => ({
  lightbox: {
    position: 'absolute',
    width: 567,
    backgroundColor: '#F3F3F3',
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: '8px'
  },
  contentBlock: {
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 46px'
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  deleteButton: {
    color: '#EC5540',
    width: '226px',
    borderColor: '#EC5540',
    backgroundColor: '#FFFFFF'
  },
  cancelButton: {
    width: '226px',
    color: '#3B3A3D',
    borderColor: '#CECECF',
    backgroundColor: '#E6E7E7'
  },
  saveButton: {
    width: '226px',
    borderColor: '#513BE3',
    backgroundColor: '#E2DEFA',
    color: '#513BE3',
    borderWidth: '2px'
  },
  tagList: {
    padding: '24px 74px',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    flex: 1,
    borderRadius: '4px',
    border: 0,
    height: '25em',
    overflowY: 'scroll',
  },
  heading: {
    fontSize: '24px',
    textTransform: 'capitalize'
  },
  captionBlock: {
    display: 'flex',
    justifyContent: 'start',
    flex: 1,
    margin: '0px 46px'
  },
  caption: {
    fontWeight: 700,
    width: '113px',
    fontSize: '12px',
    textTransform: 'capitalize'
  },
  captionedValue: {
    fontSize: '12px'
  },
  textInput: {
    backgroundColor: '#FFFFFF',
    flex: 1,
    border: 0
  }
}));

export const TierLabels = [
  { singular: 'dataset', plural: 'datasets', editable: false },
  { singular: 'category', plural: 'categories', editable: true },
  { singular: 'tag', plural: 'tags', editable: true },
];

export type RawTagData = {
  tag: string,
  created_at?: Date,
  modified_at?: Date,
  modified_by?: string,
};

export type RawCategoryData = {
  [category: string]: RawCategoryData | RawTagData[],
};

export type TagData = {
  label: string,
  selected: boolean,
};

export type CategoryData = {
  identifier: string,
  subs: DescriptorData,
  expanded: boolean,
};

export type DescriptorData = CategoryData[] | TagData[];

export function isCategoryData(input: DescriptorData):input is CategoryData[] {
  return (input.length > 0) && ((input as CategoryData[])[0].identifier !== undefined);
}

// depth is zero at top
export function existsAtDepth(data:DescriptorData, query:string, depth:number, select?:string[]):boolean {
  if (depth === 0) {
    if (isCategoryData(data)) {
      return data.reduce((found:boolean, ele) => found || (ele.identifier === query), false);
    } else {
      return data.reduce((found:boolean, ele) => found || (ele.label === query), false);
    }
  } else if (isCategoryData(data)) {
    if (select && select.length > 0) {
      const ele = data.find((x) => x.identifier === select[0]);
      return ele ? existsAtDepth(ele.subs, query, depth-1, select?.slice(1)) : false;
    }
    return data.reduce((found:boolean, ele) => found || existsAtDepth(ele.subs, query, depth-1), false);
  }

  return false; // this can happen if a depth is requested that is deeper than the "data" structure
}