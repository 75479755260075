import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

type DomainListProps = {
  domain: string,
  onChange: (x:string) => void,
};
export function DomainList(props:DomainListProps):React.ReactElement {
  var [domainList, setDomainList] = React.useState([] as string[]);
  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => props.onChange(e.target.value as string);

  const classes = useStyles();

  const loadDomains = async () => {
    const response = await axios.get('/admin/domains');
    setDomainList(response.data.domains);
  };

  useEffect(() => {
    loadDomains();
  }, []);

  return (
    domainList.length === 0 ? <div></div> : 
    <FormControl className={classes.formControl}>
      <InputLabel id="domain-select-label">Domain</InputLabel>
      <Select
        labelId="domain-select-label"
        id="domain-select"
        value={props.domain}
        onChange={handleChange}
        autoWidth
      >
        { domainList.map((d) => (<MenuItem value={d}>{d}</MenuItem>)) }
        <MenuItem value=""><em>None</em></MenuItem>
      </Select>
    </FormControl>
  );
}