import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
    Card,
    CardActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import zendeskLogo from '../logos/zendeskLogo.png'
import redditLogo from '../logos/redditLogo.png'
import firefliesAiLogo from '../logos/firefliesAiNewLogo.png'
import slackLogo from '../logos/slackLogo.png'
import jiraServiceDeskLogo from '../logos/jiraServiceDeskLogo.png'
import clientSuccessLogo from '../logos/clientSuccessLogo.png'
import stripeLogo from '../logos/stripeLogo.png'
import twilioLogo from '../logos/twilioLogo.png'
import wixAnswersLogo from '../logos/wixLogo.png'
import intercomServiceDeskLogo from '../logos/intercomServiceDeskLogo.png'
import salesforceLogo from '../logos/salesforceLogo.jpg'
import snowflakeLogo from '../logos/snowflakeLogo.png'
import teamSupportLogo from '../logos/teamSupportLogo.png'
import gongLogo from '../logos/gongLogo.png'
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import axios from "axios";
import {copyObj} from "../util";
import {OpinionatedLoadSpinner} from "../Components/LoadSpinner";
import {useAxiosRequest} from "../Components/AxiosRequest";
import {Alert, AlertTitle} from "@material-ui/lab";
import {IDataContainer} from "../util/api";
import {Check} from "@material-ui/icons";
import ConditionalShow from "../Components/ConditionalLoad";
import LinkList from "../Components/LinkList";
import OAuth2Login from "../Components/OAuthLogin";

const oAuthRedirectUrl = `${window.location.protocol}//${window.location.host}/integrations/oauth2/redirect`


interface IIntegrationConfig {
    label: 'Zendesk' | 'Salesforce' | 'Snowflake' | 'Zendesk Chat' | 'ServiceDesk' | 'TeamSupport' | 'Fireflies AI' | 'Slack' | 'Reddit' | 'ClientSuccess' | 'Intercom' | 'Stripe' | 'WixAnswers' | 'Twilio' | 'Gong'
    name: 'zendesk' | 'salesforce' | 'snowflake' | 'zendesk_chat' | 'servicedesk' | 'teamsupport' | 'fireflies_ai' | 'slack' | 'reddit' | 'clientsuccess' |'intercom' | 'stripe' | 'wix-answers' | 'twilio' | 'gong'
    logo: string
    subLogoText?: string
    oauth?: {
        scope?: string | string[]
    }
    conf: {
        label: string
        name: string
        type?: string
        required?: boolean
    }[]
}


const integrationsConfig: IIntegrationConfig[] = [
    {
        label: 'Twilio',
        name: 'twilio',
        logo: twilioLogo,
        conf: [
            {
                label: 'Account SID',
                name: 'sid',
                type: 'password',
            },
            {
                label: 'Auth token',
                name: 'token',
                type: 'password',
            },
        ]
    },
    {
        label: 'Gong',
        name: 'gong',
        logo: gongLogo,
        conf: [
            {
                label: 'Access Key',
                name: 'key_id',
                type: 'password',
            },
            {
                label: 'Access Key Secret',
                name: 'key_secret',
                type: 'password',
            },
        ]
    },
    {
        label: "WixAnswers",
        name: "wix-answers",
        logo: wixAnswersLogo,
        conf: [
            {
                label: 'Key ID',
                name: 'key_id',
                type: 'password',
            },
            {
                label: 'Secret',
                name: 'secret',
                type: 'password',
            },
            {
                label: 'Subdomain',
                name: 'subdomain',
            }
        ]
    },
    {
        label: "Stripe",
        name: "stripe",
        logo: stripeLogo,
        conf: [
            {
                label: 'API Key',
                name: 'api_key',
                type: 'password',
            }
        ]
    },
    {
        label: 'Zendesk',
        name: 'zendesk',
        logo: zendeskLogo,
        subLogoText: 'Zendesk Support',
        conf: [
            {
                label: 'Subdomain',
                name: 'subdomain',
            },
            {
                label: 'Email',
                name: 'email',
            },
            {
                label: 'API Token',
                name: 'token',
                type: 'password',
            },
        ]
    },
    {
        label: 'Zendesk Chat',
        name: 'zendesk_chat',
        logo: zendeskLogo,
        subLogoText: 'Zendesk Chat',
        oauth: {
            scope: 'read',
        },
        conf: [
            {
                label: 'Client ID',
                name: 'client_id',
                type: 'password',
            },
            {
                label: 'Client Secret',
                name: 'client_secret',
                type: 'password',
            },
            {
                label: 'Subdomain',
                name: 'subdomain',
            },
        ]
    },
    {
        label: 'Salesforce',
        name: 'salesforce',
        logo: salesforceLogo,
        conf: [
            {
                label: 'Consumer Key',
                name: 'client_id',
                type: 'password'
            },
            {
                label: 'Consumer Secret',
                name: 'client_secret',
                type: 'password'
            },
            {
                label: 'Instance',
                name: 'instance',
            },
        ]
    },
    {
        label: "ClientSuccess",
        name: "clientsuccess",
        logo: clientSuccessLogo,
        conf: [
            {
                label: 'Username',
                name: 'username',
            },
            {
                label: 'Password',
                name: 'password',
            }
        ]
    },
    {
        label: "ServiceDesk",
        name: "servicedesk",
        logo: jiraServiceDeskLogo,
        conf: [
            {
                label: 'Email',
                name: 'username',
            },
            {
                label: 'API Key',
                name: 'password',
                type: 'password',
            },
            {
                label: 'Instance URL',
                name: 'url',
            }
        ]
    },
    {
        label: 'Snowflake',
        name: 'snowflake',
        logo: snowflakeLogo,
        conf: [
            {
                label: 'Client ID',
                name: 'client_id',
            },
            {
                label: 'Client Secret',
                name: 'client_secret',
                type: 'password',
            },
            {
                label: 'Role',
                name: 'role',
            },
            {
                label: 'Account',
                name: 'account',
            }
        ]
    },
    {
        label: 'TeamSupport',
        name: 'teamsupport',
        logo: teamSupportLogo,
        conf: [
            {
                label: 'Organization ID',
                name: 'organization_id',
            },
            {
                label: 'URL',
                name: 'url',
            },
            {
                label: 'Token',
                name: 'token',
                type: 'password',
            },
        ]
    },
    {
        label: 'Slack',
        name: 'slack',
        logo: slackLogo,
        conf: []
    },
]


export default function SettingsPage() {
    const {loading, error, resp} = useAxiosRequest<IDataContainer<{ integration_service: string }[]>>(
        {url: '/integrations/config/list'}
    )
    // if (loading) {
    //     return <OpinionatedLoadSpinner/>
    // }
    if (loading) {
        return <OpinionatedLoadSpinner/>
    }
    if (error || resp === undefined) {
        return <Alert severity="error">Error Loading Settings</Alert>
    }
    const existingIntegrations = resp?.data.data.map(x => x.integration_service)
    return (
        <Grid container spacing={4}>
            <Grid item container spacing={3} style={{marginLeft: '5%'}}>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>Integrations</Typography>
                </Grid>
                <Grid xs={12} item container spacing={4} style={{marginLeft: '5%', marginRight: '10%'}}>
                    {integrationsConfig.map((conf, keyIdx) => {
                        const exists = existingIntegrations.includes(conf.name)
                        console.log('exists', exists, existingIntegrations, conf.name)
                        return (
                            <React.Fragment key={keyIdx + conf.name}>
                                <AddIntegration conf={conf} exists={exists}/>
                            </React.Fragment>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

interface IAddIntegrationProps {
    conf: IIntegrationConfig
    exists: boolean
}

function AddIntegration(props: IAddIntegrationProps) {
    const {conf} = props;

    const [open, setOpen] = React.useState(false);
    const [config, configSetter] = React.useState<any>({creds: {}});
    const [loading, loadingSetter] = React.useState<boolean>(false);
    const [error, errorSetter] = React.useState<{ title?: string, message: string }>({message: ''});

    const getUnfilledFields = () => {
        const unfilledFields: string[] = []
        for (let i = 0; i < conf.conf.length; i++) {
            const v = conf.conf[i]
            if (!config['creds'][v.name]) {
                unfilledFields.push(v.label)
            }
        }
        return unfilledFields
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        errorSetter({message: ''})
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Grid item xs={12} sm={6} md={3} xl={2} key={conf.label} style={{maxWidth: 500}}>
                <Card style={{borderRadius: 10}}>
                    <CardContent>
                        <Grid container alignContent={'center'}
                              style={{height: 250}}>
                            <Grid item container justify={'center'}>
                                <Grid item>
                                    <img src={conf.logo} style={{maxHeight: 125, maxWidth: 200}} alt={'logo'}/>
                                </Grid>
                                <ConditionalShow visible={conf.subLogoText !== undefined}>
                                    <Grid item>
                                        {conf.subLogoText}
                                    </Grid>
                                </ConditionalShow>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider/>
                    <CardActions>
                        <ConditionalShow visible={props.exists}>
                            <Check style={{'color': 'green'}}/>
                        </ConditionalShow>
                        <Button
                            color={'primary'}
                            variant='outlined'
                            size="small"
                            onClick={handleClickOpen}
                        >

                            {!props.exists ? 'Configure' : 'Update'}
                        </Button>
                        <ConditionalShow visible={props.exists}>
                            <div style={{marginLeft: 'auto'}}>
                                <LinkList items={[
                                    {
                                        label: 'Delete',
                                        onClick: async () => {
                                            await axios.delete('/integrations/config/delete', {
                                                data: {name: conf.name}
                                            })
                                            window.location.reload()
                                        }
                                    },
                                ]}/>
                            </div>
                        </ConditionalShow>
                    </CardActions>
                </Card>
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <ConditionalShow hidden={loading} loadingWhileHidden={true}>
                    <DialogContent>
                        <Grid container justify={'center'} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'h5'}>Add Integration</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ConditionalShow visible={error.message.length > 0}>
                                    <Alert severity="error">
                                        <ConditionalShow visible={!!error.title}>
                                            <AlertTitle>{error.title}</AlertTitle>
                                        </ConditionalShow>
                                        {error.message}
                                    </Alert>
                                </ConditionalShow>
                            </Grid>
                            <Grid item>
                                <img src={conf.logo} style={{height: 125}} alt={'logo'}/>
                            </Grid>

                            <ConditionalShow visible={conf.name === 'salesforce'}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Connected App Configuration Steps</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow><TableCell>
                                                1. Name: Stylo Analytics
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                2. Contact Email: support@askstylo.com
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                3. Check "Enable OAuth Settings"
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                4. Callback URL: {oAuthRedirectUrl}
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                5. Select OAuth Scopes: api, refresh_token and offline_access
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                6. Click the Save at the bottom of the page
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                7. Copy the consumer key and secret key into the fields below.
                                                "Instance" is your salesforce subdomain you can find in the browser:
                                                https://{"{instance}"}.salesforce.com
                                            </TableCell></TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ConditionalShow>
                            <ConditionalShow visible={conf.name === 'zendesk_chat'}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Zendesk Chat API Client Configuration Steps</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow><TableCell>
                                                1. Client Name: Stylo Analytics
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                2. Company: Stylo
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                3. Redirect URL: {oAuthRedirectUrl}
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                4. Copy the client ID and client secret into the fields below.
                                            </TableCell></TableRow>
                                            <TableRow><TableCell>
                                                4. The "Instance" field below is your zendesk subdomain.
                                                https://{"{subdomain}"}.zendesk.com
                                            </TableCell></TableRow>


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ConditionalShow>


                            <Grid item container justify={'center'} spacing={2}>
                                {conf.conf.map((fieldConf, confIdx) => {
                                    return (
                                        <Grid item key={confIdx}>
                                            <TextField
                                                variant={'outlined'}
                                                type={fieldConf.type || 'text'}
                                                label={fieldConf.label}
                                                autoComplete={'off'}
                                                size={"small"}
                                                value={config['creds'][fieldConf.name] || ''}
                                                onChange={x => {
                                                    const cpy = copyObj(config)
                                                    if (!cpy['creds']) {
                                                        cpy['creds'] = {}
                                                    }
                                                    cpy['creds'][fieldConf.name] = x.target.value
                                                    configSetter(cpy)
                                                }}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ConditionalShow visible={conf.name === 'salesforce'}>
                            <OAuth2Login
                                buttonText={'Connect'}
                                buttonDisabled={!config?.creds?.client_id || !config?.creds?.instance || !config?.creds?.client_secret}
                                authorizationUrl={`https://${config?.creds?.instance}.salesforce.com/services/oauth2/authorize`}
                                responseType="code"
                                clientId={config?.creds?.client_id || ''}
                                redirectUri={oAuthRedirectUrl}
                                onSuccess={async (x?: any) => {
                                    const code = x?.code
                                    if (!code || typeof code !== 'string') {
                                        errorSetter({
                                            title: 'Failed Connect Via OAuth Flow',
                                            message: 'Successfully connected to SFDC but did not recieve code',
                                        })
                                        return
                                    }
                                    if (!config || !config.creds) {
                                        errorSetter({
                                            title: 'Unknown error occurred',
                                            message: 'Unknown error occurred',
                                        })
                                        return
                                    }
                                    config.creds.code = code
                                    config.creds.redirect_uri = oAuthRedirectUrl
                                    console.log('SETTING CONFIG', config)
                                    try {
                                        loadingSetter(true)
                                        await axios.post('/integrations/config/set', {
                                            name: conf.label,
                                            integration_service: conf.name,
                                            config: config,
                                        })
                                        handleClose()
                                        window.location.reload()
                                    } catch (e: any) {
                                        if (e?.response?.data?.detail?.alert?.message) {
                                            console.log('resp data', e?.response?.data?.detail?.alert)
                                            errorSetter(e?.response?.data?.detail?.alert)
                                        } else {
                                            errorSetter({
                                                title: 'Unknown error occurred',
                                                message: 'Received status ' + (e?.response?.status || 'Unknown')
                                            })
                                        }
                                        console.error('error setting config', e.response.data)
                                    } finally {
                                        loadingSetter(false)
                                    }
                                }}
                                onFailure={(x?: any) => {
                                    errorSetter({
                                        title: 'Failed Connect Via OAuth Flow',
                                        message: x + '',
                                    })
                                }}/>
                        </ConditionalShow>
                        <ConditionalShow visible={conf.name === 'zendesk_chat'}>
                            <OAuth2Login
                                buttonText={'Connect'}
                                buttonDisabled={!config?.creds?.client_id || !config?.creds?.client_secret || !config?.creds?.subdomain}
                                authorizationUrl={`https://www.zopim.com/oauth2/authorizations/new`}
                                scope={conf.oauth?.scope || ''}
                                responseType="code"
                                clientId={config?.creds?.client_id || ''}
                                redirectUri={encodeURIComponent(oAuthRedirectUrl)}
                                state={'asdlfkjadsflkajsdf'}
                                additionalPayload={{
                                    subdomain: config?.creds?.subdomain
                                }}
                                onSuccess={async (x?: any) => {
                                    const code = x?.code
                                    if (!code || typeof code !== 'string') {
                                        errorSetter({
                                            title: 'Failed Connect Via OAuth Flow',
                                            message: 'Successfully connected to SFDC but did not recieve code',
                                        })
                                        return
                                    }
                                    if (!config || !config.creds) {
                                        errorSetter({
                                            title: 'Unknown error occurred',
                                            message: 'Unknown error occurred',
                                        })
                                        return
                                    }
                                    config.creds.code = code
                                    config.creds.redirect_uri = oAuthRedirectUrl
                                    console.log('SETTING CONFIG', config)
                                    try {
                                        loadingSetter(true)
                                        await axios.post('/integrations/config/set', {
                                            name: conf.label,
                                            integration_service: conf.name,
                                            config: config,
                                        })
                                        handleClose()
                                        window.location.reload()
                                    } catch (e: any) {
                                        if (e?.response?.data?.detail?.alert?.message) {
                                            console.log('resp data', e?.response?.data?.detail?.alert)
                                            errorSetter(e?.response?.data?.detail?.alert)
                                        } else {
                                            errorSetter({
                                                title: 'Unknown error occurred',
                                                message: 'Received status ' + (e?.response?.status || 'Unknown')
                                            })
                                        }
                                        console.error('error setting config', e.response.data)
                                    } finally {
                                        loadingSetter(false)
                                    }
                                }}
                                onFailure={(x?: any) => {
                                    errorSetter({
                                        title: 'Failed Connect Via OAuth Flow',
                                        message: x + '',
                                    })
                                }}/>
                        </ConditionalShow>
                        <ConditionalShow visible={conf.name === 'slack'}>
                            <OAuth2Login
                                buttonText={'Connect'}
                                buttonDisabled={false} // TODO
                                authorizationUrl={`https://slack.com/oauth/v2/authorize`}
                                scope={'channels:history channels:read users:read groups:history groups:read'}
                                responseType="code"
                                clientId={'1275179655222.1390847856577'}
                                redirectUri={encodeURIComponent('https://app.askstylo.com/integrations/oauth2/redirect')}
                                state={'asdlfkjadsflkajsdf'}
                                additionalPayload={{
                                    subdomain: config?.creds?.subdomain
                                }}
                                onSuccess={async (x?: any) => {
                                    const code = x?.code
                                    if (!code || typeof code !== 'string') {
                                        errorSetter({
                                            title: 'Failed Connect Via OAuth Flow',
                                            message: 'Successfully connected to SFDC but did not recieve code',
                                        })
                                        return
                                    }
                                    if (!config || !config.creds) {
                                        errorSetter({
                                            title: 'Unknown error occurred',
                                            message: 'Unknown error occurred',
                                        })
                                        return
                                    }
                                    config.creds.code = code
                                    config.creds.redirect_uri = oAuthRedirectUrl
                                    console.log('SETTING CONFIG', config)
                                    try {
                                        loadingSetter(true)
                                        await axios.post('/integrations/config/set', {
                                            name: conf.label,
                                            integration_service: conf.name,
                                            config: config,
                                        })
                                        handleClose()
                                        window.location.reload()
                                    } catch (e: any) {
                                        if (e?.response?.data?.detail?.alert?.message) {
                                            console.log('resp data', e?.response?.data?.detail?.alert)
                                            errorSetter(e?.response?.data?.detail?.alert)
                                        } else {
                                            errorSetter({
                                                title: 'Unknown error occurred',
                                                message: 'Received status ' + (e?.response?.status || 'Unknown')
                                            })
                                        }
                                        console.error('error setting config', e.response.data)
                                    } finally {
                                        loadingSetter(false)
                                    }
                                }}
                                onFailure={(x?: any) => {
                                    errorSetter({
                                        title: 'Failed Connect Via OAuth Flow',
                                        message: x + '',
                                    })
                                }}/>
                        </ConditionalShow>
                        <ConditionalShow visible={conf.name === 'snowflake'}>
                            <OAuth2Login
                                buttonText={'Connect'}
                                buttonDisabled={!config?.creds?.client_id || !config?.creds?.client_secret || !config?.creds?.account || !config?.creds?.role}
                                authorizationUrl={`https://${config?.creds?.account}.snowflakecomputing.com/oauth/authorize`}
                                scope={encodeURIComponent(`refresh_token session:role:${config?.creds?.role}`)}
                                responseType="code"
                                clientId={encodeURIComponent(config?.creds?.client_id)}
                                redirectUri={encodeURIComponent(oAuthRedirectUrl)}
                                onSuccess={async (x?: any) => {
                                    const code = x?.code
                                    if (!code || typeof code !== 'string') {
                                        errorSetter({
                                            title: 'Failed Connect Via OAuth Flow',
                                            message: 'Successfully connected to SFDC but did not recieve code',
                                        })
                                        return
                                    }
                                    if (!config || !config.creds) {
                                        errorSetter({
                                            title: 'Unknown error occurred',
                                            message: 'Unknown error occurred',
                                        })
                                        return
                                    }
                                    config.creds.code = code
                                    config.creds.redirect_uri = oAuthRedirectUrl
                                    console.log('SETTING CONFIG', config)
                                    try {
                                        loadingSetter(true)
                                        await axios.post('/integrations/config/set', {
                                            name: conf.label,
                                            integration_service: conf.name,
                                            config: config,
                                        })
                                        handleClose()
                                        window.location.reload()
                                    } catch (e: any) {
                                        if (e?.response?.data?.detail?.alert?.message) {
                                            console.log('resp data', e?.response?.data?.detail?.alert)
                                            errorSetter(e?.response?.data?.detail?.alert)
                                        } else {
                                            errorSetter({
                                                title: 'Unknown error occurred',
                                                message: 'Received status ' + (e?.response?.status || 'Unknown')
                                            })
                                        }
                                        console.error('error setting config', e.response.data)
                                    } finally {
                                        loadingSetter(false)
                                    }
                                }}
                                onFailure={(x?: any) => {
                                    errorSetter({
                                        title: 'Failed Connect Via OAuth Flow',
                                        message: x + '',
                                    })
                                }}/>
                        </ConditionalShow>

                        <ConditionalShow
                            hidden={conf.name === 'salesforce' || conf.name === 'zendesk_chat' || conf.name === 'slack' || conf.name === 'snowflake'}>
                            <Button
                                onClick={async () => {
                                    try {
                                        loadingSetter(true)
                                        await axios.post('/integrations/config/set', {
                                            name: conf.label,
                                            integration_service: conf.name,
                                            config: config,
                                        })
                                        handleClose()
                                        window.location.reload()
                                    } catch (e: any) {
                                        if (e?.response?.data?.detail?.alert?.message) {
                                            console.log('resp data', e?.response?.data?.detail?.alert)
                                            errorSetter(e?.response?.data?.detail?.alert)
                                        } else {
                                            errorSetter({
                                                title: 'Unknown error occurred',
                                                message: 'Received status ' + (e?.response?.status || 'Unknown')
                                            })
                                        }
                                        console.error('error setting config', e.response.data)
                                    } finally {
                                        loadingSetter(false)
                                    }
                                }}
                                color="primary"
                                disabled={getUnfilledFields().length > 0}
                            >
                                Configure
                            </Button>
                        </ConditionalShow>
                        <Button onClick={handleClose} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </ConditionalShow>
            </Dialog>
        </React.Fragment>
    )
}
