import React, {useState} from "react";
import {useAuth0} from "../react-auth0-spa";
import {Checkbox, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios, {AxiosError} from "axios";
import Typography from "@material-ui/core/Typography";
import ConditionalShow from "../Components/ConditionalLoad";

export default function StyloAdmin() {
    const auth = useAuth0()
    const domain = auth.user.email.split('@')[1]
    if (domain !== 'styloml.com' && domain !== 'askstylo.com' && domain !== 'styloteams.com') {
        return <div>Unauthorized</div>
    }

    return (
        <React.Fragment>
            <PasswordResetLink/>
        </React.Fragment>
    )

}

function PasswordResetLink() {
    const [passwordResetEmail, passwordResetEmailSetter] = useState<string>('')
    const [passwordResetEmailFirstName, passwordResetEmailFirstNameSetter] = useState<string>('')
    const [sendInviteEmail, sendInviteEmailSetter] = useState<boolean>(false)
    const [passwordResetResponse, passwordResetResponseSetter] = useState<string>('')
    const [redirectSubdomain, redirectSubdomainSetter] = useState<string>('app')

    return (
        <div>
            <Typography>
                Generate Password Reset Link
            </Typography>
            Send Invite Email:
            <Checkbox
                checked={sendInviteEmail}
                onChange={e => sendInviteEmailSetter(e.target.checked)}
                inputProps={{'aria-label': 'controlled'}}
            />
            <br/>
            <TextField
                style={{width: 400}}
                label="User Email"
                value={passwordResetEmail}
                onChange={e => passwordResetEmailSetter(e.target.value)}
            />
            <br/>
            <TextField
                style={{width: 400}}
                label="Redirect Subdomain"
                value={redirectSubdomain}
                onChange={e => redirectSubdomainSetter(e.target.value.toLowerCase())}
            />
            <ConditionalShow visible={sendInviteEmail}>
                <br/>
                <TextField
                    style={{width: 400}}
                    label="First Name"
                    value={passwordResetEmailFirstName}
                    onChange={e => passwordResetEmailFirstNameSetter(e.target.value)}
                />
            </ConditionalShow>
            <br/>
            <Button
                style={{marginBottom: 20}}
                onClick={() => {
                    if (passwordResetEmailFirstName.trim().length === 0 && sendInviteEmail) {
                        passwordResetResponseSetter(`FAILED First name must be filled in to send email`)
                        return
                    }
                    axios.post<{ ticket: string }>('/admin/users/password_reset_ticket', {
                        email: passwordResetEmail.trim(),
                        first_name: passwordResetEmailFirstName.trim(),
                        send_invite_email: sendInviteEmail,
                        redirect_domain: redirectSubdomain,
                    }).then(x => {
                        passwordResetResponseSetter(x.data.ticket)
                    }).catch((e: AxiosError) => {
                        console.log("Error generating pwreset ticket", e.toJSON())
                        passwordResetResponseSetter(`FAILED (check console)`)
                    })
                }}
            >
                Submit
            </Button>
            <br/>
            <Typography>
                {passwordResetResponse}
            </Typography>
        </div>
    )

}