import React from 'react';
import { Button, TextField, Typography } from "@material-ui/core";
import { LightboxStyles, DescriptorData, isCategoryData, CategoryData, TierLabels, existsAtDepth } from './TagTypes'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const capitalize = (x:string) => x[0].toUpperCase() + x.slice(1);

type TagAddLightboxProps = {
  closeFcn: React.MouseEventHandler,
  hierarchicalData: DescriptorData,
  executeFcn: (newValue:string, ancestry:string[]) => void,
};
export function TagAddLightbox(props:TagAddLightboxProps) {
  const [lineage, setLineage] = React.useState(TierLabels.map(x => ''));
  const classes = LightboxStyles();

  const getDepthOptions = (x: DescriptorData, route: string[], depth: number):string[] => {
    if (depth === 0) {
      return isCategoryData(x) ? x.map(y => y.identifier) : x.map(y => y.label);
    } else {
      return getDepthOptions(
        (x as CategoryData[]).reduce((prev, y) => ((y.identifier === route[0]) ? y.subs : prev), [] as DescriptorData),
        route.slice(1),
        depth-1
      );
    }
  };

  const tagDuplicated = ():boolean => existsAtDepth(props.hierarchicalData, lineage.slice(-1)[0], TierLabels.length-1, lineage.slice(0, 1));

  const setLineageValue = (index: number, value: string): void => {
    lineage[index] = value;
    setLineage(lineage.map((x, i) => (i === index) ? value : x));
  };

  type CategoryInput = {
    freeInput?: string,
    value: string
  }

  const filter = createFilterOptions<CategoryInput>();

  const makeParentInput = (depth: number) => 
    (<div className={classes.contentBlock}>
      <Autocomplete
        className={classes.textInput}
        value={{ value: lineage[depth] } as CategoryInput}
        onChange={(_event, newValue) => {
          if (typeof newValue === 'string') {
            setLineageValue(depth, newValue);
          } else if (newValue) {
            setLineageValue(depth, newValue.value);
          }
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo={TierLabels[depth].editable}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              value: params.inputValue,
              freeInput: `Create ${TierLabels[depth].singular} "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => (typeof(option) == 'string') ? option : option.freeInput || option.value}
        options={ getDepthOptions(props.hierarchicalData, lineage, depth).map((x) => { return { value: x } as CategoryInput }) }
        renderInput={ (params) => (<TextField variant="outlined" {...params} />) }
        renderOption={(option, { inputValue }) => {
          if (option.freeInput) {
            return (<div><span>{ option.freeInput }</span></div>);
          }
          const matches = match(option.value, inputValue);
          const parts = parse(option.value, matches);
  
          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </div>);

  const makeLabelInput = (depth: number) => {
    return (<div className={classes.contentBlock}>
      <TextField
        className={classes.textInput}
        variant="outlined"
        value={lineage[depth]}
        onChange={(evt) => setLineageValue(depth, evt.target.value)}
        error={tagDuplicated()}
        helperText={tagDuplicated() ? `${capitalize(TierLabels[depth].singular)} name already exists inside ${TierLabels[depth-1].singular}` : ''}
      />
    </div>);
  };

  const readyToSubmit = () => {
    return !tagDuplicated() && TierLabels.reduce((ready, _label, i) => Boolean(ready && lineage[i]).valueOf(), true);
  };

  return (<div className={classes.lightbox}>
    <div className={classes.contentBlock}>
      <Typography className={classes.heading}>Add {capitalize(TierLabels.slice(-1)[0].singular)}</Typography>
    </div>

    { TierLabels.map((tierName, depth) => (
      <div key={depth}>
        <div className={classes.captionBlock}>
          <Typography className={classes.caption}>{capitalize(tierName.singular)}</Typography>
        </div>
        { ((depth === TierLabels.length-1) ? makeLabelInput : makeParentInput)(depth) }
      </div>
    )) }

    <div className={classes.contentBlock}>
      <div className={classes.buttonBlock}>
        <Button className={classes.cancelButton} variant="outlined" onClick={props.closeFcn}>Cancel</Button>
        <Button className={classes.saveButton} variant="outlined" disabled={!readyToSubmit()} onClick={ (event: React.MouseEvent) => {
          event.stopPropagation();
          props.executeFcn(lineage.slice(-1)[0], lineage.slice(0, -1));
        } }>Save</Button>
      </div>
    </div>
  </div>);
}